import React from 'react';
import { Button, useTheme } from '@mui/material';

interface ButtonProps {
  text: string;
  onClick: (e: any) => void;
  type?: 'primary' | 'secondary' | 'link';
}

const CommonButton = ({ text, type, onClick }: ButtonProps) => {
  const theme = useTheme();

  const PrimaryButton = {
    fontSize: '1rem',
    color: `${theme.palette.common.white} !important`,
    fontWeight: 600,
    borderRadius: '18px',
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.common.black,
      color: `${theme.palette.common.white} !important`,
    },
  };

  const SecondaryButton = {
    fontSize: '1rem',
    color: `${theme.palette.common.black} !important`,
    fontWeight: 600,
    borderRadius: '18px',
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.common.black,
      color: `${theme.palette.common.white} !important`,
    },
  };

  const LinkButton = {
    fontSize: '1.2rem',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 600,
    borderRadius: '18px',
    background: 'transparent',
    padding: '0',
    '&:hover': {
      background: 'transparent',
      color: `${theme.palette.common.black} !important`,
    },
  };

  const Style = type === 'link' ? LinkButton : type === 'secondary' ? SecondaryButton : PrimaryButton;

  return (
    <Button sx={Style} onClick={onClick}>
      {text}
    </Button>
  );
};

export default CommonButton;
