import { Box, Button, SxProps, Theme } from '@mui/material';
import CommonButton from './Button';
import { PopupModal } from 'react-calendly';
import { useState } from 'react';

const BookDemoButton = ({
  variant = 'primary',
  text = 'Book a demo',
  color = '',
  bgColor = '',
  buttonStyle = {},
  disabled = false,
  onClick,
  onClose,
}: {
  variant?: 'primary' | 'secondary' | 'tertiary';
  text?: string;
  color?: string;
  bgColor?: string;
  disabled?: boolean;
  buttonStyle?: SxProps<Theme> | undefined;
  onClick?: () => void;
  onClose?: () => void;
}) => {
  const [showCalendly, setShowCalendly] = useState(false);
  const calendlyProfile = process.env.NEXT_PUBLIC_CALENDLY_PROFILE || 'yogesh-financeops/30min';

  return (
    <Box>
      {showCalendly && (
        <PopupModal
          url={`https://calendly.com/${calendlyProfile}`}
          rootElement={document.getElementById('calendly') as HTMLElement}
          open={showCalendly}
          onModalClose={() => {
            setShowCalendly(false);
            onClose?.();
          }}
        />
      )}
      {variant === 'tertiary' ? (
        <Button
          onClick={() => {
            onClick?.();
            setShowCalendly(true);
          }}
          disabled={disabled}
          sx={{
            border: '1px solid #D9D9D9',
            borderRadius: '23px',
            color: color ? color : '#3E3E3E',
            fontSize: '14px',
            textTransform: 'none',
            background: bgColor ? bgColor : '#6461F3',
            ...buttonStyle,
          }}
        >
          {text}
        </Button>
      ) : (
        <CommonButton
          text={text}
          type={variant}
          onClick={() => {
            setShowCalendly(true);
          }}
        />
      )}
    </Box>
  );
};

export default BookDemoButton;
