import { Box, Button, Popover, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import logoBlack from '../../../public/images/Logo-Black.svg';
import logo from '../../../public/images/Logo-White.svg';
import smallLogo from '../../../public/images/logos/logo.png';
import BookDemoButton from '../../@core/components/BookDemoButton';
import AutomationIcon from './Icons/AutomationIcon';
import AutonomusIcon from './Icons/AutonomusIcon';
import EngagementIcon from './Icons/EngagementIcon';
import FinancialInstitutions from './Icons/FinancialInstitutions';
import HealthCareIcon from './Icons/HealthCare';
import LawFirmIcon from './Icons/LawFirmIcon';
import ReceiveableIcon from './Icons/RecieveableIcon';
import ReportingIcon from './Icons/ReportingIcon';
import RiskManagementIcon from './Icons/RiskManagementIcon';
import SMBIcon from './Icons/SMB';

const DownArrow = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      fill={color}
      height='10px'
      width='10px'
      version='1.1'
      id='Layer_1'
      viewBox='0 0 330 330'
      xmlSpace='preserve'
    >
      <path
        id='XMLID_225_'
        d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'
      />
    </svg>
  );
};

const SolutionsSubMenu = [
  {
    title: 'Autonomous Collections',
    link: 'autonomus-collections',
    icon: AutonomusIcon,
  },
  {
    title: 'Accounts Receivable Management',
    link: 'accounts-receivable-management',
    icon: ReceiveableIcon,
  },
  {
    title: 'AI Automation and Workflow Optimization',
    link: 'ai-automation-and-workflow-optimization',
    icon: AutomationIcon,
  },
  // {
  //   title: 'Accounts Payable Management',
  //   link: 'accounts-payable-management',
  //   icon: PayableIcon,
  // },
  {
    title: 'Advanced Risk Management',
    link: 'advanced-risk-management',
    icon: RiskManagementIcon,
  },
  {
    title: 'Financial Reporting and Analysis',
    link: 'financial-reporting-and-analysis',
    icon: ReportingIcon,
  },
  {
    title: 'Customer Engagement and Self-Service',
    link: 'customer-engagement-and-self-service',
    icon: EngagementIcon,
  },
];

const IndustriesSubMenu = [
  {
    title: 'Financial Institutions',
    link: 'financial-institutions',
    icon: FinancialInstitutions,
  },
  {
    title: 'Healthcare',
    link: 'healthcare',
    icon: HealthCareIcon,
  },
  {
    title: 'Small and Mid-sized Business Firms',
    link: 'sbms',
    icon: SMBIcon,
  },
  {
    title: 'Law Firms',
    link: 'law-firms',
    icon: LawFirmIcon,
  },
];

const SubMenuItem = ({ title, link, icon }: { title: string; link: string; icon: any }) => {
  const IconComponent = icon;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Link href={link} passHref>
        <Button>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Box sx={{ pt: '3px', display: 'flex', alignItems: 'start' }}>
              <IconComponent />
            </Box>
            <Typography
              sx={{
                fontSize: '1.2rem',
                color: '#3E3E3E',
                fontWeight: '600',
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Button>
      </Link>
    </Box>
  );
};

const NavigationBar = ({ variant = 'primary' }) => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSolutions, setAnchorElSolutions] = useState(null);

  const isSecondaryVariant = variant === 'secondary';

  const handleIndustriesClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSolutionsClick = (event: any) => {
    setAnchorElSolutions(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSolutions = () => {
    setAnchorElSolutions(null);
  };

  const open = Boolean(anchorEl);
  const openSolutions = Boolean(anchorElSolutions);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .MuiToolbar-root': {
          minHeight: 'unset',
        },
        '& .MuiToolbar-root .MuiButtonBase-root': {
          color: isSecondaryVariant ? theme.palette.common.black : theme.palette.common.white,
          fontWeight: 600,
          fontSize: isTab ? '0.8rem' : '1.6rem',
        },
        '& .MuiButton-icon': {
          ml: '2px',
        },
      }}
    >
      <Link href='/' passHref>
        <Box>
          <Image
            src={isTab ? smallLogo : isSecondaryVariant ? logoBlack : logo}
            alt={'FinanceOps'}
            width={isTab ? 20 : 120}
            height={isTab ? 20 : 30}
          />
        </Box>
      </Link>

      <Toolbar
        sx={{
          ...(isTab && {
            px: '0',
            '& .MuiButtonBase-root ': {
              fontSize: '1rem',
            },
          }),
        }}
      >
        <Link href='/products' passHref>
          <Button>Products</Button>
        </Link>
        <Button
          onClick={handleSolutionsClick}
          endIcon={<DownArrow color={variant === 'secondary' ? '#000' : '#fff'} />}
        >
          Solutions
        </Button>
        <Button
          onClick={handleIndustriesClick}
          endIcon={<DownArrow color={variant === 'secondary' ? '#000' : '#fff'} />}
        >
          Industries
        </Button>
        <Link href='/pricing' passHref>
          <Button>Pricing</Button>
        </Link>
        <Link href='/about-us' passHref>
          <Button>About us</Button>
        </Link>
      </Toolbar>
      {!isTab && (
        <Box sx={{ ml: '160px' }}>
          <BookDemoButton />
        </Box>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '600px',
              maxWidth: '80vw',
              flexWrap: 'wrap',
            }}
          >
            {IndustriesSubMenu?.map((value, index) => {
              return (
                <Box key={index} sx={{ width: '45%' }}>
                  <SubMenuItem title={value.title} link={value.link} icon={value.icon} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>
      <Popover
        open={openSolutions}
        anchorEl={anchorElSolutions}
        onClose={handleCloseSolutions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '600px', flexWrap: 'wrap' }}>
            {SolutionsSubMenu?.map((value, index) => {
              return (
                <Box key={index} sx={{ width: isMobile ? '100%' : '45%' }}>
                  <SubMenuItem title={value.title} link={value.link} icon={value.icon} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default NavigationBar;
